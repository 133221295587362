import React, { useState } from "react";

function Content() {
    const [showMore, setShowMore] = useState([]);

    return (
        <>

            <div className="text-container font-montserrat-light">
                <p>
                    Towing provides quality towing services in Los Angeles, San Diego, and Riverside, CA areas with flatbed towing,
                    heavy truck towing, towing, and emergency roadside assistance services.
                </p>
                <div id="continue_read" className={showMore ? 'opacity-0 overflow-hidden max-h-0 transition-all ease-in duration-500 font-montserrat-light' : 'opacity-100 overflow-hidden max-h-[500px] transition-all ease-in duration-500 font-montserrat-light'}>
                    <h2>
                        We work with highly trained experts who offer 24-hour roadside assistance to meet any need at any time. Our towing Company works hard to ensure your trust with
                        our fast service at competitive prices. We are ready to respond to all your car emergencies 24 hours a day, 7 days a
                        week. When it comes to our company in Los Angeles, the state of California is much more at risk than just a tow
                        truck. Many towing companies are known for their emergency towing services, but our towing company has different
                        priorities. For example, roadside assistance is one of the services of Towing Company and they do it all. Carlock
                        services for the driver, tire repair for the unfortunate Los Angeles driver with extra tires in need of the repair,
                        and battery drain so you do not have to stand in the parking lot for hours late at night waiting for someone to find
                        bouncing cables or car lock keys.
                    </h2>
                </div>

            </div>
            <div className="relative">
                <button onClick={() => setShowMore(!showMore)} type="submit" className="read-or-hide" id="readbtn">
                    {showMore ? 'read more' : 'show less'}
                </button>
            </div>
        </>
    );
}

export default Content