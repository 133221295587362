import React from "react";
import CountUp from 'react-countup';

function Counters() {


    return (
        <>
            <div className='w-1/3 h-auto flex flex-col space-y-5 justify-center items-center max-lg:w-full'>
                <i className="fa-sharp fa-solid fa-star text-mainColor text-7xl hover:scale-[1.3] transition-all duration-300"></i>
                <CountUp className="text-white text-3xl font-montserrat-light counter opacity-0" start={0} end={25} duration={5} delay={2}/>
                <span className="text-white text-2xl font-montserrat">Years of Experience</span>
            </div>
            <div className='w-1/3 h-auto flex flex-col space-y-5 justify-center items-center max-lg:w-full'>
                <i className="fa-sharp fa-solid fa-car-burst text-white text-7xl hover:scale-[1.3] transition-all duration-300"></i>
                <CountUp className="text-white text-3xl font-montserrat-light counter opacity-0" start={0} end={25} duration={5} delay={2}/>
                <span className="text-white text-2xl font-montserrat">Vehicles Towed</span>
            </div>
            <div className='w-1/3 h-auto flex flex-col space-y-5 justify-center items-center max-lg:w-full'>
                <i className="fa-sharp fa-solid fa-users text-mainColor text-7xl hover:scale-[1.3] transition-all duration-300"></i>
                <CountUp className="text-white text-3xl font-montserrat-light counter opacity-0" start={0} end={25} duration={5} delay={2}/>
                <span className="text-white text-2xl font-montserrat">Workers in Team</span>
            </div>
        </>
    );
}

export default Counters