import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeFifth() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false
                }
            },
        ]
    };
    return (
        <>
            <section className={`h-auto w-full max-lg:mt-10 mt-20`}>
                <Slider {...settings}>
                    <div className="p-40 max-lg:p-5 bg-black">
                        <div className="w-full h-full mx-auto rounded-xl text-center">
                            <h2 className="text-white font-montserrat text-4xl">Testimonials</h2>
                            <p className="p-3 text-white font-montserrat font-medium">
                                My experience with Protto Legal has been excellent. My attorney, Feliks, gave my case all
                                the time and attention that was needed to be successful in court. I very much appreciate the
                                knowledge and support I received through Protto."
                            </p>
                        </div>
                    </div>
                    <div className="p-40 max-lg:p-5 bg-[#CE82B9]">
                        <div className="w-full h-full mx-auto rounded-xl text-center">
                            <h2 className="text-white font-montserrat text-4xl">Testimonials</h2>
                            <p className="p-3 text-white font-montserrat font-medium">
                                My experience with Protto Legal has been excellent. My attorney, Feliks, gave my case all
                                the time and attention that was needed to be successful in court. I very much appreciate the
                                knowledge and support I received through Protto."
                            </p>
                        </div>
                    </div>
                    <div className="p-40 max-lg:p-5 bg-[#7A7777]">
                        <div className="w-full h-full mx-auto rounded-xl text-center">
                            <h2 className="text-white font-montserrat text-4xl">Testimonials</h2>
                            <p className="p-3 text-white font-montserrat font-medium">
                                My experience with Protto Legal has been excellent. My attorney, Feliks, gave my case all
                                the time and attention that was needed to be successful in court. I very much appreciate the
                                knowledge and support I received through Protto."
                            </p>
                        </div>
                    </div>
                </Slider>
            </section>

        </>
    );
}

export default HomeFifth    