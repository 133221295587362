import { Link } from "react-router-dom";
import ScrollToTop from "../footer/scrollToTop";
import React from 'react';

function Footer() {
    return (
        <>
            <footer>
                <section className="bg-[#313131] p-5">
                    <div className="mx-auto max-w-screen-xl flex justify-start space-x-5 max-lg:flex-col max-lg:space-y-3 max-lg:space-x-0">
                        <div className="w-1/3 max-lg:w-full">
                            <h2 className="font-montserrat text-white text-2xl mb-3">SERVICES</h2>
                            <ul className="font-montserrat-light text-[#909090]">
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Heavy Duty Towing
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Roadside Assistence
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Jump Starts
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Flat Tire Repair
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Fuel Delivery
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Vehicle Accidents
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Lockouts
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-service'}>
                                        Boat & ATV Transportation and Restoration
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-1/3 max-lg:w-full">
                            <h2 className="font-montserrat text-white text-2xl mb-3">BLOG</h2>
                            <ul className="font-montserrat-light text-[#909090]">
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Classic Car Towing
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Classic Car Crash
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Long Distance Towing
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Prepare Your Vehicle for Transport
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Miles and Miles and Miles…
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Beware of Damage
                                    </Link>
                                </li>
                                <li className="transition-all duration-300 hover:text-mainColor">
                                    <Link to={'/single-blog'}>
                                        Tips for Avoiding Car Breakdowns
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="bg-black p-5">
                    <div className="mx-auto max-w-screen-xl flex justify-center">
                        <h2 className="font-montserrat-light text-white text-xl max-lg:text-base">
                            Copyright © {(new Date().getFullYear())} HamionTowing | Powered by HamionTowing
                        </h2>
                    </div>
                </section>
            </footer>
            <ScrollToTop />
        </>
    );
}

export default Footer