import React from 'react';
import Home from './pages/home';
import { Route, Routes } from 'react-router-dom';
import Header from './pages/components/layouts/header';
import Footer from './pages/components/layouts/footer';
import Contact from './pages/contact';
import SingleService from './pages/singleService';
import SingleBlog from './pages/singleBlog';
import Services from './pages/services';
import Blog from './pages/blog';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/single-service' element={<SingleService />} />
        <Route path='/single-blog' element={<SingleBlog />} />
        <Route path='/services' element={<Services />} />
        <Route path='/blog' element={<Blog />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
