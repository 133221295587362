import CardIMG1 from '../../../assets/images/towing-service-los-angeles.png'
import CardIMG2 from '../../../assets/images/heavy-duty-towing-los-angeles.png'
import CardIMG3 from '../../../assets/images/roadside-assistance-los-angeles.png'
import CardIMG4 from '../../../assets/images/emergency-towing-los-angeles.png'
import React from 'react';

function HomeFourth() {
    return (
        <>
            <section className="mt-20 max-lg:mt-10 max-w-screen-xl mx-auto">
                <div className="flex justify-center items-center max-lg:mb-5">
                    <h2 className="font-montserrat text-black text-3xl">Our Services</h2>
                </div>
                <div className="flex space-x-3 mt-10 max-lg:flex-col max-lg:space-y-3 max-lg:space-x-0 max-lg:p-10">
                    <div className='fadein1 -mt-20 w-1/4 max-lg:w-full'>
                        <div className="shadow-[10px_9px_10px_0px_rgba(0,0,0,0.5)] p-5 bg-white">
                            <div className='flex flex-col space-y-5 items-center justify-center'>
                                <img src={CardIMG1} alt='ll' className='w-[50%] object-contain h-16' />
                                <h3 className='font-montserrat text-2xl text-center'>Towing Service</h3>
                                <p className='font-montserrat-light text-center'>
                                    Hamion Towing provides local and long-distance towing equipment hauling, flatbed and motorcycle, car, truck towing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='fadein2 -mt-20 w-1/4 max-lg:w-full'>
                        <div className="shadow-[10px_9px_10px_0px_rgba(0,0,0,0.5)] p-5 bg-white">
                            <div className='flex flex-col space-y-5 items-center justify-center'>
                                <img src={CardIMG2} alt='ll' className='w-[50%] object-contain h-16' />
                                <h3 className='font-montserrat text-2xl text-center'>Heavy Duty Towing</h3>
                                <p className='font-montserrat-light text-center'>
                                    Heavy duty towing is one of Hamion Towing's specialties, no matter the size of your rig, we can haul it.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='fadein3 -mt-20 w-1/4 max-lg:w-full'>
                        <div className="shadow-[10px_9px_10px_0px_rgba(0,0,0,0.5)] p-5 bg-white">
                            <div className='flex flex-col space-y-5 items-center justify-center'>
                                <img src={CardIMG3} alt='ll' className='w-[50%] object-contain h-16' />
                                <h3 className='font-montserrat text-2xl text-center'>Roadside Assistance</h3>
                                <p className='font-montserrat-light text-center'>
                                    Our roadside assistance services include unlocks, tire changes, battery maintenance and fuel delivery.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='fadein3 -mt-20 w-1/4 max-lg:w-full'>
                        <div className="shadow-[10px_9px_10px_0px_rgba(0,0,0,0.5)] p-5 bg-white">
                            <div className='flex flex-col space-y-5 items-center justify-center'>
                                <img src={CardIMG4} alt='ll' className='w-[50%] object-contain h-16' />
                                <h3 className='font-montserrat text-2xl text-center'>Emergency Service</h3>
                                <p className='font-montserrat-light text-center'>
                                    Hamion Towing Los Angeles provides 24/7 service because you never know when life will get in the way.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeFourth