import { Link } from "react-router-dom";
import React from 'react';

function FooterForm() {
    return (
        <>
            <section className="bg-[#4D4D4D] p-5 mt-20">
                <div className="flex justify-between max-w-screen-xl mx-auto space-x-5 max-lg:flex-col max-lg:space-y-5 max-lg:space-x-0">
                    <div className="w-2/3 max-lg:w-full">
                        <h2 className="font-montserrat-light text-3xl mb-5 text-white">CONTACT <span className="font-montserrat">FORM</span></h2>
                        <form className="font-montserrat flex flex-col space-y-5">
                            <div className="flex justify-between space-x-5 max-lg:flex-col max-lg:space-y-5 max-lg:space-x-0">
                                <input type="text" placeholder="Name" className="w-1/2 max-lg:w-full p-2 focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" required />
                                <input type="text" placeholder="Address" className="w-1/2 max-lg:w-full p-2 focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" required />
                            </div>
                            <div className="flex justify-between space-x-5 max-lg:flex-col max-lg:space-y-5 max-lg:space-x-0">
                                <input type="tel" placeholder="Phone" className="w-1/2 max-lg:w-full p-2 focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" required />
                                <input type="email" placeholder="Email" className="w-1/2 max-lg:w-full p-2 focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" required />
                            </div>
                            <textarea placeholder="Message" rows={10} className="p-2 w-full focus:ring ring-mainColor focus:outline-none focus:border-transparent transition-all duration-300" required></textarea>
                            <input type="submit" value={'Send Message'} className="max-lg:w-full font-montserrat cursor-pointer w-1/5 bg-black text-mainColor uppercase py-3 px-1 border-2 border-mainColor hover:text-white hover:border-white transition-all duration-300" />
                        </form>
                    </div>
                    <div className="w-1/3 max-lg:w-full">
                        <h2 className="font-montserrat-light text-3xl mb-5 text-white">CONTACT <span className="font-montserrat">INFO</span></h2>
                        <ul className="flex flex-col space-y-5">
                            <li className="flex items-center space-x-3 font-montserrat-light text-white">
                                <i className="fa-sharp fa-solid fa-envelope text-mainColor text-2xl"></i>
                                <Link to={'mailto:info@hamiontowing.com'}>
                                    Info@hamiontowing.com
                                </Link>
                            </li>
                            <hr />
                            <li className="flex items-center space-x-3 font-montserrat-light text-white">
                                <i className="fa-sharp fa-solid fa-phone text-mainColor text-2xl"></i>
                                <Link to={'tel:8556721911'}>
                                    (855) 672-1911
                                </Link>
                            </li>
                            <hr />
                            <li className="flex items-center space-x-3 font-montserrat-light text-white">
                                <i className="fa-sharp fa-regular fa-clock text-mainColor text-2xl"></i>
                                <span>24 hours a day, 7 days a week</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterForm