import { Link } from "react-scroll";
import React from 'react';

function ScrollToTop() {
    return (
        <>
            <Link id="scroll" activeClass='ll' to={'mastHeader'} spy={true} smooth={true} duration={700} className="text-white text-2xl font-montserrat cursor-pointer">
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                </span>
            </Link>
        </>
    );
}

export default ScrollToTop