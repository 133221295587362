import React from "react";
import { Link } from "react-router-dom";
import FooterForm from "./components/footer/footerForm";
import SingleServiceIMG1 from '../assets/images/10.jpg'
import SingleServiceIMG2 from '../assets/images/4-2-1.jpg'
import SingleServiceIMG3 from '../assets/images/55.jpg'

function Services() {
    return (
        <>
            <section className="bg-mainColor h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">Services</h2>
            </section>
            <div className="max-w-screen-xl mx-auto py-6  sm:px-14 lg:px-24 xl:px-0 mt-10 flex flex-col lg:flex-row max-lg:space-y-8 lg:space-x-6 pb-24">
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img src={SingleServiceIMG1} alt="55" className={'transition-all duration-500 ease-linear group-hover:scale-110 w-full h-[310px] object-cover'} />
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link to={'/single-service'} className="text-[#00173c] font-montserrat text-[16px] md:text-[22px] transition-all duration-300 hover:text-mainColor">
                            HEAVY DUTY TOWIMG
                        </Link>
                        <p className="mt-3 font-montserrat-light text-[#777] text-[14px]">
                            Hamion Towing provides fast, reliable medium and heavy duty towing services. Because we know that heavy duty towing is a serious responsibility, we make sure that the professionals at our affiliates are well equipped and well managed, their top quality heavy duty vehicles are always well maintained and on schedule.
                        </p>
                        <p className="mt-3">
                            <Link to={'/single-service'} className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-mainColor transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img alt="554" src={SingleServiceIMG2} className={'transition-all duration-500 ease-linear group-hover:scale-110 w-full h-[310px] object-cover'} />
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link to={'/single-service'} className="text-[#00173c] font-bold font-montserrat text-[16px] md:text-[22px] transition-all duration-300 hover:text-mainColor">
                            ROADSIDE ASSISTENCE
                        </Link>
                        <p className="mt-3 font-montserrat-light text-[#777] text-[14px]">
                            You may already have a roadside assistance strategy through your insurance company. In an ideal world, you can call your insurer of their roadside assistance number and get timely and reasonable service. However, often towing vehicles can take a long, very long time to get through these solutions.
                        </p>
                        <p className="mt-3">
                            <Link to={'/single-service'} className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-mainColor transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="group max-w-lg max-lg:mx-auto cursor-pointer w-[90%] lg:w-1/3 bg-[white] rounded-[4px]  shadow-[0px_20px_60px_0px_rgba(0,0,0,.08)]">
                    <div className="overflow-hidden relative">
                        <img alt="333" src={SingleServiceIMG3} className={'transition-all duration-500 ease-linear group-hover:scale-110 w-full h-[310px] object-cover'} />
                    </div>
                    <div className="p-[10px] sm:p-[20px]">
                        <Link to={'/single-service'} className="text-[#00173c] font-bold font-montserrat text-[16px] md:text-[22px] transition-all duration-300 hover:text-mainColor">
                            Jump Starts
                        </Link>
                        <p className="mt-3 font-montserrat-light text-[#777] text-[14px]">
                            Leaving work or the mall to find your car battery dead is no fun. The machine can still operate on its own, but it needs to start flying first. The problem with needing to jump-start is that most people don’t have jumper cables.
                        </p>
                        <p className="mt-3">
                            <Link to={'/single-service'} className="mt-3 underline decoration-2 underline-offset-4 font-pop font-bold text-mainColor transition-all duration-500 hover:text-[#00173c]">
                                READ MORE
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
            <FooterForm />
        </>
    );
}

export default Services