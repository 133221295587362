import React from "react";
import { Link } from "react-router-dom";
import BannerIMG from '../../../assets/images/photo_2022-09-28_18-42-38-1.jpg'

function Banner() {
    return (
        <>
            <section className="w-full h-auto">
                <div className="bg-no-repeat h-[650px] w-full bg-cover bg-center max-lg:bg-left" style={{ backgroundImage: `url(${BannerIMG})` }}>
                    <div className="max-lg:text-3xl flex items-center justify-center h-full w-full font-montserrat text-white font-bold text-6xl">
                        <h2>You Call It, We Haul It</h2>
                    </div>
                </div>
                <div className="flex max-lg:block">
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-black flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-regular fa-clock text-mainColor text-5xl"></i>
                        <span className="text-white uppercase text-2xl font-montserrat">Fast Arrival Time</span>
                    </div>
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-white flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-solid fa-phone text-mainColor text-5xl"></i>
                        <Link to={'tel:8556721911'} className="text-black text-2xl font-montserrat">(855) 672-1911</Link>
                    </div>
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-black flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-solid fa-truck-fast text-mainColor text-5xl"></i>
                        <span className="text-white uppercase text-2xl font-montserrat">24/7 towing service</span>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Banner