import SingleServiceBannerIMG from '../assets/images/10.jpg'
import FooterForm from './components/footer/footerForm';
import { Link } from 'react-router-dom';
import React from 'react';
import SectionIMG1 from '../assets/images/6.png'
import SectionIMG2 from '../assets/images/7.png'
import SectionIMG3 from '../assets/images/8.png'
import SectionIMG4 from '../assets/images/9.png'

function SingleService() {
    return (
        <>
            <section className="w-full">
                <div className="bg-no-repeat h-[650px] w-full bg-cover bg-center max-lg:bg-left bg-green-500" style={{ backgroundImage: `url(${SingleServiceBannerIMG})` }}>
                    <div className="max-lg:text-3xl flex flex-col items-center space-y-3 justify-center h-full w-full font-montserrat text-mainColor font-bold text-6xl">
                        <h2>HEAVY DUTY TOWIMG</h2>
                        <span className="text-[2.75rem] max-lg:text-[1.5rem] text-white font-montserrat">WE TOW ANY SIZE VEHICLE</span>
                    </div>
                </div>
                <div className="flex max-lg:block">
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-black flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-regular fa-clock text-mainColor text-5xl"></i>
                        <span className="text-white uppercase text-2xl font-montserrat">Fast Arrival Time</span>
                    </div>
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-white flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-solid fa-phone text-mainColor text-5xl"></i>
                        <Link to={'tel:8556721911'} className="text-black text-2xl font-montserrat">(855) 672-1911</Link>
                    </div>
                    <div className="w-1/3 max-lg:w-full h-[120px] bg-black flex justify-center items-center space-x-3">
                        <i className="fa-sharp fa-solid fa-truck-fast text-mainColor text-5xl"></i>
                        <span className="text-white uppercase text-2xl font-montserrat">24/7 towing service</span>
                    </div>
                </div>
            </section>
            <section className="mt-20 max-lg:flex-col max-lg:space-y-5">
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col max-lg:space-y-5">
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG1} alt='pp' className='w-full h-full object-cover fadeinLeft ml-[-300px] max-lg:ml-0' />
                    </div>
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-3xl max-lg:text-center">
                            Heavy Duty <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Towing</span>
                        </h2>
                        <p className='font-montserrat-light'>
                            Hamion Towing provides fast, reliable medium and heavy duty towing services. Because we know that heavy duty towing
                            is a serious responsibility, we make sure that the professionals at our affiliates are well equipped and well
                            managed, their top quality heavy duty vehicles are always well maintained and on schedule.
                        </p>
                    </div>
                </div>
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col-reverse max-lg:space-y-5">
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-center max-lg:text-3xl max-lg:mt-5">
                            What Do Our Heavy Duty Towing <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Services</span> Cover?
                        </h2>
                        <p className='font-montserrat-light'>
                            The size and power of our heavy-duty tow trucks create an optimal opportunity for our towing company to exceed
                            customer expectations by offering multi-use capabilities while towing multiple vehicles for a much cheaper and
                            faster towing method and offering business support in moving their heavy or custom-built equipment. We offer a wide
                            variety of towing and even car lockout solutions for customers looking for help, and more importantly, we offer
                            heavy duty solutions by tying up with the most professional service providers in the business. That means we can
                            help you tow SUVS, trailer trucks, and even trucks and boats as big as ours.
                        </p>
                    </div>
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG2} alt='pp' className='w-full h-full object-cover fadeinRight ml-[300px] max-lg:ml-0' />
                    </div>
                </div>
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col max-lg:space-y-5">
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG3} alt='pp' className='w-full h-full object-cover fadeinLeft ml-[-300px] max-lg:ml-0' />
                    </div>
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-3xl max-lg:text-center">
                            Heavy <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Duty Truck</span> Towing
                        </h2>
                        <p className='font-montserrat-light'>
                            Our heavy duty tow truck can help tow a wide variety of vehicles such as 16 – passenger vans, cube vans, minibusses,
                            box trucks, ambulances, awkward pickups, tractors, and utility trucks. We have contracts with several businesses
                            that depend on our on-call service every day. Our medium and heavy duty truck drivers are constantly trained to
                            assure that your heavy duty truck system is treated promptly and with the utmost care no matter what the case may
                            be.
                        </p>
                    </div>
                </div>
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col-reverse max-lg:space-y-5">
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-center max-lg:text-3xl max-lg:mt-5">
                            Heavy Duty <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Recovery</span> Services
                        </h2>
                        <p className='font-montserrat-light'>
                            Hamion Towing Company provides towing recovery services for all heavy duty truck breakdowns and off-road winching,
                            retrofitting, and recovery. Their recovery services use current technologies and recovery systems to guarantee fast
                            and safe and secure recovery of your asset. Our services include but are not limited to heavy duty accident and
                            off-road winch recovery services, all types of heavy vehicle towing from garbage trucks, tractor trailer towing to
                            construction trucks, and our clients consist of individuals in the company industry, each and every one of which we
                            are offered with the utmost dedication from our side because our team believes that we are here because of you and
                            therefore we are pleased to serve you with utmost dedication.
                            <br />
                            Contact Hamion Towing Company at <Link className='text-mainColor' to={'tel:8556721911'}>855 – 672 – 1911</Link> for your medium to heavy duty towing needs.
                        </p>
                    </div>
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG4} alt='pp' className='w-full h-full object-cover fadeinRight ml-[300px] max-lg:ml-0' />
                    </div>
                </div>
            </section>
            <FooterForm />
        </>
    );
}

export default SingleService