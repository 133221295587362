import React from "react";
import HomeFirstIMG from '../../../assets/images/6-1536x1152-removebg-preview.png'
import Content from "./HomeFirst/readMore";

function HomeFirst() {
    return (
        <>
            <section className="max-w-screen-xl flex max-lg:space-x-0 space-x-3 mx-auto mt-20 max-lg:block max-lg:px-10 max-lg:mt-10">
                <div className="w-1/2 max-lg:w-full">
                    <img className="h-full w-full object-contain" src={HomeFirstIMG} alt='hh' />
                </div>
                <div className="w-1/2 max-lg:w-full max-lg:flex max-lg:justify-center max-lg:items-center max-lg:flex-col">
                    <h2 className="uppercase text-2xl font-montserrat">towing services</h2>
                    <Content />
                    <ul className="font-montserrat-light mt-3">
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Car Towing Services</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Flatbed Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Light &amp; Medium Duty Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Heavy Duty Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Luxury Car Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Motorcycle Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Bus & RV Towing</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Roadside Assistance</span>
                        </li>
                        <hr />
                        <li className="space-x-2">
                            <i className="fa-sharp fa-solid fa-check text-mainColor text-xl"></i>
                            <span className="text-lg">Accident Recovery</span>
                        </li>
                        <hr />
                    </ul>
                </div>
            </section>
        </>
    );
}

export default HomeFirst