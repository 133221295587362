import { Link } from 'react-router-dom';
import icon1 from '../../../assets/images/icons/ImageToStl.com_6-removebg-preview.svg'
import icon2 from '../../../assets/images/icons/ImageToStl.com_8-removebg-preview.svg'
import icon3 from '../../../assets/images/icons/ImageToStl.com_3-removebg-preview.svg'
import icon4 from '../../../assets/images/icons/ImageToStl.com_7-removebg-preview.svg'
import icon5 from '../../../assets/images/icons/ImageToStl.com_1-removebg-preview.svg'
import IMG from '../../../assets/images/12-removebg-preview.png'
import React from 'react';

function HomeSecond() {
    return (
        <>
            <section className="mt-20">
                <div className="flex justify-center items-center font-montserrat">
                    <h2 className="text-4xl">
                        Towing <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Services</span>
                    </h2>
                </div>
                <div className="flex h-[450px] max-lg:h-full mt-10 max-lg:block">
                    <div className="w-1/4 max-lg:w-full">
                        <ul className="w-full flex flex-col space-y-10 max-lg:space-y-3">
                            <li className="flex justify-end w-full max-lg:block">
                                <div className='flex justify-center items-center max-lg:justify-start'>
                                    <img src={icon1} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Classic Car Towing</Link>
                                </div>
                            </li>
                            <li className="w-full flex justify-center max-lg:block">
                                <div className='flex justify-center items-center max-lg:justify-start'>
                                    <img src={icon2} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Miles and Miles and Miles…</Link>
                                </div>
                            </li>
                            <li className="flex justify-end w-full max-lg:block">
                                <div className='flex justify-center items-center max-lg:justify-start'>
                                    <img src={icon3} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Long Distance Towing</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="w-2/4 flex justify-center items-center max-lg:w-full">
                        <img src={IMG} className='w-full h-full object-contain' alt={'ll'} />
                    </div>
                    <div className="w-1/4 max-lg:w-full">
                        <ul className="w-full flex flex-col space-y-10 max-lg:space-y-3">
                            <li className="w-full">
                                <div className='flex items-center'>
                                    <img src={icon4} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Classic Car Crash</Link>
                                </div>
                            </li>
                            <li className="flex justify-center w-full max-lg:block">
                                <div className='flex items-center'>
                                    <img src={icon5} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Beware of Damage</Link>
                                </div>
                            </li>
                            <li className="w-full">
                                <div className='flex items-center'>
                                    <img src={icon3} alt={'kk'} className='w-20' />
                                    <Link className='font-montserrat-light' to={'/single-blog'}>Long Distance Towing</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSecond