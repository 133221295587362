import SectionIMG1 from '../../../assets/images/11.png'
import SectionIMG2 from '../../../assets/images/10.png'
import SectionIMG3 from '../../../assets/images/22.jpg'
import React from 'react';

function HomeSixth() {
    return (
        <>
            <section className="mt-20 max-lg:flex-col max-lg:space-y-5">
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col max-lg:space-y-5">
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG1} alt='pp' className='w-full h-full object-cover fadeinLeft ml-[-300px] max-lg:ml-0' />
                    </div>
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-3xl max-lg:text-center">
                            Towing <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Services</span>
                        </h2>
                        <p className='font-montserrat-light'>
                            Towing provides quality towing services in Los Angeles, San Diego, and Riverside, CA areas with flatbed towing,
                            heavy truck towing, towing, and emergency roadside assistance services. We work with highly trained experts who
                            offer 24-hour roadside assistance to meet any need at any time. Our towing Company works hard to ensure your trust
                            with our fast service at competitive prices. We are ready to respond to all your car emergencies 24 hours a day, 7
                            days a week.
                            When it comes to our company in Los Angeles, the state of California is much more at risk than just a tow truck.
                            Many towing companies are known for their emergency towing services, but our towing company has different
                            priorities. For example, roadside assistance is one of the services of Towing Company and they do it all. Carlock
                            services for the driver, tire repair for the unfortunate Los Angeles driver with extra tires in need of the repair,
                            and battery drain so you do not have to stand in the parking lot for hours late at night waiting for someone to find
                            bouncing cables or car lock keys.
                        </p>
                    </div>
                </div>
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col-reverse max-lg:space-y-5">
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-center max-lg:text-3xl max-lg:mt-5">
                            Our Roadside <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Assistance</span> Service
                        </h2>
                        <p className='font-montserrat-light'>
                            Our Los Angeles towing company offers a wide range of roadside assistance services. We can help with almost any
                            difficulty that leaves you on the side of the road. We are equipped to provide roadside towing and other assistance
                            for all makes/models of cars, RVs, motorcycles, luxury cars, mobile homes, buses, medium and heavy-duty trucks.
                            We are experts in providing towing services and emergency roadside assistance solutions. We can help with any type
                            of roadside assistance services you need. It is our pleasure to help serve in all types of emergencies. We have the
                            technology and power to get you and your vehicle to safer areas. If your car needs a gas or mechanical work, we can
                            help. We will be right there to solve the problem. Our towing company is staffed with experienced and reputable
                            technicians that you can count on to do an excellent job, every time.
                        </p>
                    </div>
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG2} alt='pp' className='w-full h-full object-cover fadeinRight ml-[300px] max-lg:ml-0' />
                    </div>
                </div>
                <div className="flex h-[600px] max-lg:h-full max-lg:flex-col max-lg:space-y-5">
                    <div className="w-1/2 max-lg:w-full">
                        <img src={SectionIMG3} alt='pp' className='w-full h-full object-cover fadeinLeft ml-[-300px] max-lg:ml-0' />
                    </div>
                    <div className='w-1/2 flex flex-col justify-center space-y-5 px-10 max-lg:w-full'>
                        <h2 className="text-4xl font-montserrat max-lg:text-3xl max-lg:text-center">
                            The <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Best Towing</span> Company
                        </h2>
                        <p className='font-montserrat-light'>
                            If we are unable to get your vehicle moving again, we additionally offer towing services to take your car home or to
                            a nearby shop for repairs. Our operators take the utmost care of your vehicle to ensure it arrives safely. No one
                            wants to be stuck on the side of the road with a car problem, but when it happens, you want to be able to call
                            someone you can count on. Our towing company provides the roadside assistance and towing services you need, offering
                            a reliable 24-hour solution that is there for you whenever you need it.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSixth