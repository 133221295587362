import SectionIMG from '../../../assets/images/5.png'
import Counters from './HomeThird/counter';
import React from 'react';

function HomeThird() {
    return (
        <>
            <section className="h-[400px] max-lg:h-full max-lg:p-10" style={{ background: `linear-gradient(to bottom, rgb(0 0 0 / .6), rgb(0 0 0 / .5) ), url(${SectionIMG})`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className='max-w-screen-xl mx-auto flex h-[400px] max-lg:h-full max-lg:flex-col max-lg:space-y-5'>
                    <Counters />
                </div>
            </section>
        </>
    );
}

export default HomeThird