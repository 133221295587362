import React from "react";
import { Link } from "react-router-dom";
import SingleBlogIMG1 from '../assets/images/44.jpg'
import SingleBlogIMG2 from '../assets/images/3-4.png'
import SingleBlogIMG3 from '../assets/images/2-2.png'
import FooterForm from "./components/footer/footerForm";

function Blog() {
    return (
        <>
            <section className="bg-mainColor h-[500px] w-full flex justify-center items-center">
                <h2 className="font-montserrat font-extralight text-6xl text-white max-lg:text-2xl">Blog</h2>
            </section>
            <section className="mx-auto max-w-screen-xl mt-20 max-lg:px-10">
                <div className="flex space-x-5 mt-10 max-lg:block max-lg:space-x-0 max-lg:mx-auto">
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-blog'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={SingleBlogIMG1} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">
                                Classic Car Towing
                            </h2>
                        </Link>
                        <p className="p-2 font-montserrat-light">
                            Your classic car is more than just a vehicle. You spend hours upon hours washing, drying, waxing, detailing, and caring for your vintage car. You pay close attention to every product and towel that touches your beloved child.
                        </p>
                        <Link to={'/single-blog'} className="p-2 text-mainColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-blog'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={SingleBlogIMG2} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">
                                Classic Car Crash
                            </h2>
                        </Link>
                        <p className="p-2 font-montserrat-light">
                            One of the great joys of owning a classic car is driving it. As much as you love showing off your old baby’s good looks, driving it brings its own great joys.
                        </p>
                        <Link to={'/single-blog'} className="p-2 text-mainColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                    <div className="w-1/3 h-auto max-lg:w-full max-lg:mb-3">
                        <Link to={'/single-blog'}>
                            <div className="h-[251px]">
                                <img className="h-full w-full object-cover" src={SingleBlogIMG3} alt="" />
                            </div>
                            <h2 className="mt-2 font-montserrat font-bold text-xl p-2 max-lg:text-lg">
                                Long Distance Towing
                            </h2>
                        </Link>
                        <p className="p-2 font-montserrat-light">
                            You’ve no doubt heard horror stories about cars that have sustained extensive damage long-distance towing. While we won’t pretend those tales are false, we will say that it’s possible to haul a car many, many miles without damage.
                        </p>
                        <Link to={'/single-blog'} className="p-2 text-mainColor font-montserrat font-semibold text-sm">Read More »</Link>
                    </div>
                </div>
            </section>
            <FooterForm />
        </>
    );
}

export default Blog