import React from "react";
import SingleBlogBannerIMG from '../assets/images/classic.jpg'
import SingleBlogIMG from '../assets/images/44.jpg'
import FooterForm from "./components/footer/footerForm";
import icon1 from '../assets/images/icons/ImageToStl.com_6-removebg-preview.svg'
import icon2 from '../assets/images/icons/ImageToStl.com_7-removebg-preview.svg'
import icon3 from '../assets/images/icons/ImageToStl.com_8-removebg-preview.svg'
import icon4 from '../assets/images/icons/ImageToStl.com_2-removebg-preview.svg'
import icon5 from '../assets/images/icons/ImageToStl.com_1-removebg-preview.svg'
import icon6 from '../assets/images/icons/ImageToStl.com_3-removebg-preview.svg'
import icon7 from '../assets/images/icons/ImageToStl.com_4-removebg-preview.svg'
import icon8 from '../assets/images/icons/ImageToStl.com_5-removebg-preview.svg'
import { Link } from 'react-router-dom';

function SingleBlog() {
    return (
        <>
            <section className="w-full">
                <div className="h-[400px] items-center flex" style={{ background: ` url(${SingleBlogBannerIMG})`, backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: "no-repeat" }}>
                    <div className="max-w-screen-xl mx-auto w-full max-lg:text-center">
                        <h2 className="text-mainColor text-3xl font-montserrat">Classic Car Towing</h2>
                    </div>
                </div>
            </section>
            <section className="max-lg:px-10 mx-auto max-w-screen-xl mt-20 flex space-x-5 max-lg:mt-10 max-lg:space-x-0 max-lg:flex-col max-lg:space-y-5">
                <div className="w-1/3 bg-[#cbe3ff] p-5 max-lg:w-full max-lg:p-3">
                    <ul>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon1} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Classic Car Towing</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon2} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Classic Car Crash</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon3} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Miles and Miles and Miles…</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon4} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Prepare Your Vehicle for Transport</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon5} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Beware of Damage</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon6} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Long Distance Towing</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon7} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Tips for Avoiding Car Breakdowns</Link>
                            </div>
                        </li>
                        <li>
                            <div className='flex items-center'>
                                <img src={icon8} alt={'kk'} className='w-20' />
                                <Link className='font-montserrat-light' to={'/single-blog'}>Things You Need To Keep In Your Roadside Emergency Kit  </Link>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="w-2/3 flex justify-center items-center max-lg:w-full">
                    <img src={SingleBlogIMG} alt="55" className="w-full h-[500px] object-cover max-lg:h-auto max-lg:object-contain" />
                </div>
            </section>
            <section className="w-full flex justify-end max-w-screen-xl mx-auto mt-10 max-lg:mt-5 max-lg:px-10 max-lg:justify-center">
                <div className="font-montserrat w-[60%] max-lg:w-full">
                    <h2 className="text-4xl mb-5 text-center">
                        Classic Car <span className="italic text-[white] pt-0 pb-[5px] px-2.5 bg-gradient-to-b from-mainColor to-[#FFB6CA]"> Towing</span>
                    </h2>
                    <p className="font-montserrat-light">
                        Your classic car is more than just a vehicle. You spend hours upon hours washing, drying, waxing, detailing, and
                        caring for your vintage car. You pay close attention to every product and towel that touches your beloved child. You
                        invest a lot of time and money in the care and maintenance of your valuables.

                        Today we are talking about towing classic cars – why you might need it and how to find a towing company that will
                        handle your classic car towing needs well. If you need any help our towing company is here for you.
                    </p>
                </div>
            </section>
            <FooterForm />
        </>
    );
}

export default SingleBlog