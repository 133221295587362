import React from 'react';
import Banner from './components/home/banner';
import HomeFirst from './components/home/homeFirst';
import HomeSecond from './components/home/homeSecond';
import HomeThird from './components/home/homeThird';
import HomeFourth from './components/home/homeFourth';
import HomeFifth from './components/home/homeFifth';
import HomeSixth from './components/home/homeSixth';
import FooterForm from './components/footer/footerForm';

function Home() {
    return (
        <>
            <Banner />
            <HomeFirst />
            <HomeSecond />
            <HomeThird />
            <HomeFourth />
            <HomeFifth />
            <HomeSixth />
            <FooterForm />
        </>
    );
}

export default Home