import $ from 'jquery';

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadein1').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-top': '0px' }, 1000);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadein2').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-top': '0px' }, 1400);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadein3').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-top': '0px' }, 1800);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadein4').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-top': '0px' }, 2200);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadeinLeft').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-left': '0px' }, 700);
                }

            });
        });
    }
});

$(document).ready(function () {
    if (parseInt($(window).width()) > 1024) {
        $(window).scroll(function () {
            $('.fadeinRight').each(function (i) {

                var bottom_of_element = $(this).offset().top + $(this).outerHeight();
                var bottom_of_window = $(window).scrollTop() + $(window).height();

                if (bottom_of_window > bottom_of_element) {
                    $(this).animate({ 'opacity': '1', 'margin-left': '0px' }, 700);
                }

            });
        });
    }
});

$(document).ready(function () {
    $(window).scroll(function () {
        $('.counter').each(function (i) {

            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            if (bottom_of_window > bottom_of_element) {
                $(this).animate({ 'opacity': '1' }, 700);
            }

        });
    });
});


$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 10) {
            $('#mastHeader').removeClass("fixed");
            $('#mastHeader').addClass("static");
            $('#header__section').addClass("backdrop-blur-md");
        } else if ($(this).scrollTop() < 0.1) {
            $('#mastHeader').addClass("fixed");
            $('#header__section').removeClass("backdrop-blur-md");
        }
    });
});

$(document).ready(function () {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });
});